@import "../../../src/assets/css/Variables.New.scss";

.container {
  padding: 4px 0 4px 0;
  .inner {
    .label {
      padding-bottom: 2px;
      font-size: $fontsize_regular;
      color: $color_primary;
    }
    .explanation {
      padding-bottom: 2px;
      font-size: $fontsize_small;
      color: $color_text_secondary;
    }
    .content {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      font-size: $fontsize_large;
      padding: 2px 0 4px 0;
      .value {
        color:$color_text;
      }
      .icon {
        padding-left:4px;
        cursor: pointer;
        color:$color_primary;
      }
      .placeholder {
        color:#777777;
      }
    }
  }
}
.container.disabled {
  .inner {
    .icon {
      color:$color_disabled;
    }
  }
}
.container.small {
  .label {
    font-size: $fontsize_small;
  }
  .content {
    font-size: $fontsize_regular;
  }
}