@import "./../../../../assets/css/Variables.New.scss";

.items {
  background-color: $color_purple_10;
  padding: 4px;
}
.items.dragging {
  background-color: $color_purple_50;
}

.preview_item {
  display: flex; flex-direction: row;
  padding-bottom: 4px;
  .left {
    .image {
      width:64px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .right {
    padding-left: 4px;
    font-size: $fontsize_regular;
    .who {
      font-weight: 400;
    }
    .what {
      font-weight: 100;
    }
    .rating {
      color: gold;
    }
    .text {
      font-weight: 100;
    }
  }

}