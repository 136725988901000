@import "./../../../assets/css/Variables.New.scss";

#v-activitypackage-edit {

  #v-activitypackage-edit-actitivies,
  #v-activitypackage-prices,
  #v-activitypackage-activityprices {
    table {
      td {
        font-size: $fontsize_regular;
      } 
      td.price {
        text-align: right;
      }
      thead, tfoot {
        th {
          font-weight: 100 !important;
          text-align:left;
        }
        font-weight: 100 !important;
        color: $color_secondary;
      }
      tr.totals {
        td {
          color: $color_secondary !important;
        }
      }
      td {
        div.image-container {
          display: inline-block;
          width: 100px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    span.editbutton {
      cursor:pointer;
      padding-left:4px;
      color: $color_secondary;
    }
  }

  #v-activitypackage-activityprices {
    .participantCounts {
      display:flex; flex-direction: row;
      border-top:1px solid $color_secondary;
      .title {
        color: $color_secondary;
        padding:2px 16px 2px 0;
      }
      .item {
        padding: 2px 8px 2px 8px;
        border: 1px solid $color_secondary;
        color: $color_secondary;
        border-top:0;
        border-radius: 2px;
        margin-left: 2px;
        cursor: pointer;
      }
      .item.active {
        background-color:$color_secondary;
        color: white;
      }
    }
  }

  #v-activitypackage-edit-image {
    .image-container {
      margin-bottom: 16px;
      width:200px;
      height:200px;
      img {
        width:100%;
        height:auto;
      }
    }
  }
}
