@import "../../../src/assets/css/Variables.New.scss";

.container {
  padding: 4px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  
  .tab {
    padding:2px 8px 2px 8px;
    font-size: $fontsize_regular;
    background-color: white;
    color: $color_secondary;
    user-select: none;
    cursor: pointer;
  }
  .tab.active {
    background-color: $color_primary;
    color:white;
  }
  .tab:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .tab:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}