div#v-surprise-weather-weathers {
  display:flex;
  flex-direction: row;
  justify-items: center;
}

div.v-surprise-weather-weathers-item {
  padding:0 8px 0 8px;
  div {
    text-align: center;
  }
  img {
    width:50px; height:50px;
  }
  display:flex;
  flex-direction:column;
}

div.v-surprise-weather-entry {
  padding:0 0 8px 0;
  display: flex;
  flex-direction: row;
}

