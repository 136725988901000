@import "../../../assets/css/Variables.New.scss";

.table {
  width: 100%;
}
.secondary {
  color: $color_text_secondary;
  font-size: $fontsize_regular !important;
}

.divider {
  margin:16px 0 16px 0;
  min-height: 1px;
  background-color: $color_purple;
}