@import "../../../src/assets/css/Variables.New.scss";

.container {
  position: relative;
  margin: 1px 1px 4px 1px;
  //margin-bottom: 4px;
  select {
    padding:4px;
    width:100%;
    font-family: $font_family;
    outline: none;
    border:1px solid silver;
    border-radius: 2px;
  }
  select:focus {
    border: 1px solid $color_primary;
  }
}

.container.regular {
  select {
    padding: 4px;
    font-size: $fontsize_large;
  }
}

.container.small {
  select: {
    padding: 0;
    font-size: $fontsize_small;
  }
}