@import "../../assets/css/Variables.New.scss";

.container {
  position: relative;
  margin: 1px 1px 4px 1px;

  .textareaContainer {
    position: relative;

    textarea {
      padding:4px;
      width:100%;
      font-size: $fontsize_large;
      outline: none;
      border: 1px solid silver;
      border-radius: 2px;
    }
    textarea:focus {
      border: 1px solid $color_green;
    }
    .charactercount {
      position: absolute;
      bottom:6px;
      right:6px;
      display: inline-block;
      padding:1px 2px 1px 2px;
      font-size:  $fontsize_small;
      color: $color_purple_50;
    }
  }
}