.container {
  .inner {
    display: flex; flex-direction: row;
  }
}

// align
.container.right {
  .inner {
    justify-content: flex-end;
  }
}
.container.center {
  .inner {
    justify-content: center;
  }
}