@import "../../../src/assets/css/Variables.New.scss";

.container {
  padding: 4px;
  font-size: $fontsize_regular;
  user-select: none;
  .inner {
    display: flex; flex-direction: row;
    
    .item {
      padding:2px 8px 2px 8px;
      color: $color_primary;
      background-color: white;
      cursor: pointer;
      border: 1px solid $color_primary;
      border-right-width: 0;
    }
    .item:first-child {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }
    .item:last-child {
      border-right-width: 1px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
    .item.active {
      color: white;
      background-color: $color_primary;
    }
  }
}

.container.disabled {
  .inner {
    .item {
      color:silver;
      cursor: not-allowed;
      border-color: silver;
    }
    .item.active {
      background-color: silver;
      color: white;
    }
  }
}