@import "../../../assets/css/Variables.New.scss";

.steps {
  padding-top: 16px;
}
.step {
  padding: 4px 0 4px 0;
  display: flex; flex-direction: row;
  .title {
    font-size: $fontsize_large;
    padding: 0 4px 0 4px;
  }
}
.actions {
  display: flex; flex-direction: row;
}