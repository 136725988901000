@import "../../assets/css/Variables.New.scss";

.container {
  table {
    font-size: $fontsize_large;
  }
  table.fontSize_small {
    font-size: $fontsize_small;
  }
  table.fontSize_large {
    font-size: $fontsize_large;
  }
  table.clickable {
    cursor: pointer;
  }

  tr.clickable {
    cursor: pointer;
  };

  td {
    padding: 2px;
    vertical-align: top;
  }
  td.clickable {
    cursor: pointer;
  }
  td.label {
    color: $color_label;
  }
  td.warning {
    color: $color_warning;
  }
  td.secondaryLabel {
    color: $color_label_secondary;
  }
  td.left {
    text-align: left;
  }
  td.right {
    text-align: right;
  }
  td.center {
    text-align: center;
  }
  td.top {
    vertical-align: top;
  }
  td.bottom {
     vertical-align: bottom;
  }
  td.middle {
    vertical-align:middle;
  }
}