@import "./../../../assets/css/Variables.New.scss";

.tagsContainer,
.editContainer{
  // table.activity_tags_glossary {
  //   //font-size: $fontsize_regular;
  //   th {
  //     font-weight: normal;
  //     text-align: left;
  //     color: $color_primary;
  //   }
  // }

  div.activity_tag_pill {
    display: inline-block;
    padding:1px;
    .inner {
      padding:2px 10px 2px 10px;
      border-radius: $fontsize_small;
      font-size: $fontsize_small;
      user-select: none;
      background-color: white;
      color: $color_secondary;
      border:1px solid $color_secondary;
    }
  }
  div.activity_tag_pill.clickable {
    cursor: pointer;
  }
  div.activity_tag_pill.active {
    .inner {
      background-color: $color_secondary;
      color:white;
    }
  }
  div.activity_tag_pill.disabled {
    .inner {
      background-color: silver;
      color: white;
    }
  }
}

.editContainer {
  padding:4px;
  div.title {
    font-size: $fontsize_large;
    padding-bottom: 8px;
    color:$color_primary;
  }
  div.tags {
    padding-bottom: 16px;
  }
  div.actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  div.newtag {
    display: flex;
    flex-direction: row;
    align-items:flex-end;
    padding-bottom: 16px;
  }
}