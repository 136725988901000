@import "../../../src/assets/css/Variables.New.scss";

.container {
  padding:2px;
}

.inner {
  border:1px solid $color_error;
  border-radius: 8px;
  color: $color_text;
}

.heading {
  padding:8px;
  background-color: $color_error;
  color:white;
  font-size: $fontsize_large;
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.content {
  padding: 8px;
  background-color: white;
  color: $color_text;
  font-size: $fontsize_large;
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}