@import "./../../../assets/css/Variables.New.scss";

#v-meteocheck-list {
  div.weatherservices {
    padding:4px 4px 16px 4px;
    a {
      color: $color_green;
    }
  }
  table {
    td {
      vertical-align: top;
      padding:2px 4px 2px 0;
    } 
    tr.date {
      font-size: $fontsize_large;
      td {
        padding-top: 8px;
      }
    }
    tr.adventure {
      font-size: $fontsize_large;
      a {
        color:$color_green;
      }
    }
    tr.activity {
      font-size: $fontsize_regular;
      a {
        color:black;
        text-decoration: underline solid $color_secondary;
      }
      a:hover {
        color: $color_secondary;
      }
    }

    div.meteocontact {
      padding-top:4px;
      span:after {
        content:", ";
      }
      span:last-child:after {
        content:"";
      }
    }
  }
}