@import "./../../../assets/css/Variables.New.scss";

.container {
  table {
    td, th {
      padding:4px;
      vertical-align: top;
      font-weight: 400;

    }
    th {
      color:$color_green;
      font-weight: normal;
      text-align: right;
    }
    td:last-child {
      text-align: right;
    }
  }
  padding: 8px 0 8px 0;
}