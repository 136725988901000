@import "../../assets/css/Variables.New.scss";

.container {
  
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 4px;

  .inner {
    border-radius: 50%;
    border: 1px solid $color_confirm;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

// sizes
.small {
  .inner {
    $circle_size: 18px;
    width: $circle_size;
    height: $circle_size;
    min-width: $circle_size;
    min-height: $circle_size;
    font-size: $fontsize_small;
  }
}

.medium {
  .inner {
    $circle_size: 28px;
    width: $circle_size;
    height: $circle_size;
    min-width: $circle_size;
    min-height: $circle_size;
    font-size: $fontsize_regular;
  }
}

.large {
  .inner {
    $circle_size: 40px;
    width: $circle_size;
    height: $circle_size;
    min-width: $circle_size;
    min-height: $circle_size;
    font-size: $fontsize_large;
  }
}

// intents
.delete {
  color: $color_delete;
}