@import "./../../../../assets/css/Variables.New.scss";
.c-outlineroutes {
  margin-bottom: 2px;
  border:1px solid $color_purple;
  padding:2px 0 2px 0;
  .c-outlineroutes-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 2px;
  
    .c-outlineroutes-tabs-title {
      color:$color_purple;
      padding:4px 8px 4px 8px;
    }
  
    .c-outlineroutes-tabs {
      display: flex;
      flex-direction: row;
      border:1px solid $color_green;
      //padding:2px;
      border-radius: 4px;
    
      .c-outlineroutes-tab {
        padding:4px 8px 4px 8px;
        background-color: white;
        color:$color_green;
        user-select: none;
        cursor: pointer;
        border:2px solid white;
        border-radius: 4px;
      }
      .c-outlineroutes-tab.active {
        border-color: $color_purple;
      }
      .c-outlineroutes-tab.selected {
        background-color: $color_green;
        color:white;
      }
    }
  
  }

  .c-outlineroutes-content {
    
  }
}

