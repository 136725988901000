@import "./../../assets/css/Variables.New.scss";

div.modal {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: rgba(0, 0, 0, 0.6);
  display:flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  padding-top:16px;
  z-index: 999; // needs to be less than google drive
  div.inner {
    border-radius: 8px;
    background-color: white;
    max-height: 90%;
    height:auto;
    width: 500px;
    max-width: 90%;
    position: relative;

    overflow-y: auto;
    div.header {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: $color_green;
      color:white;
      padding:8px;
      div.title {
        font-size: $fontsize_large;
      }
      div.buttons_right,
      div.buttons_left {
        display: flex;
        flex-direction: row;
        button.button {
          width:28px;
          height:22px;
          background-color: $color_green;
          color:white;
          border:1px solid white;
          border-radius: 2px;
          text-align: center;
          cursor: pointer;
          
          outline: none;
        }
      }
      div.buttons_left {
        button.button {
          margin-right:4px;
        }
      }
      div.buttons_right {
        button.button {
          margin-left:4px;
        }
      }
  
    }
    div.content {
      padding: 8px;
    }
  }
  div.inner.wide {
    width:90%;
    max-width: 1090px;
  }
  div.offscreen {
    display: none;
  }
}