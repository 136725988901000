@import "./../../../assets/css/Variables.New.scss";

div.c-providerlocations {
  display:flex;
  flex-direction: row;
  div.left {
    padding-top:2px;
    font-size: $fontsize_large;
    color:$color_green;
  }
}