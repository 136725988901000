@import "../../../assets/css/Variables.New.scss";

.container {
  .actions {
    padding:8px 0 4px 0;
  }
  .toggle {
    padding: 4px;
    label {
      span { padding-left:8px; }
      cursor: pointer;
    }
  }
}