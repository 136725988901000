@import "./../../../assets/css/Variables.New.scss";


/* Dashboard Panel */
.container {

  padding:4px;
  margin:0;
  width: 100%;
  
  .inner {
    background-color:white;
    padding:4px;
    height: 100%;
    border-radius: 4px;
  }
  .title {
    display: flex;
    flex-direction: row;
    border-bottom:1px solid #e7e7e7;
    padding:4px 0 4px 4px;
    margin-bottom: 8px;

    .title_icon {
      display: inline-block;
      width:24px;
      height:24px;
      overflow: hidden;

      img {
        width:100%;
        height:auto;
      }
    }
    .title_icon.rounded {
      border-radius: 50%;
    }

    .title_text {
      display: inline-block;
      font-size: $fontsize_largest;
      font-weight: 200;
      padding:1px 0 0 6px;
    }
  }

  .content {
    font-weight: 200;
    font-size: $fontsize_regular;
    h2 {
      font-size: 18px !important;
      font-weight:400 !important;
    }
    h3 {
      font-size: $fontsize_regular;
      font-weight:400;
    }
  }
}

.container.desktop_small {
  width:50%;
}

@media screen and (max-width: 768px) {
  .container.desktop_small {
    width:100%;
  }
  .container.mobile_small {
    width:50%;
  }

  .title_icon {
    width:16px !important;
    height:16px !important;
  }
  .title_text {
    font-size: $fontsize_regular !important;
  }

}
