@import "./../../../assets/css/Variables.New.scss";

#v-incidentprocessing-list,
#v-incidentprocessing-recent {
  table {
    td.surprise-link {
      a {
        color: $color_primary;
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
}

#v-incidentprocessing-list-filter {
  .stars {
    color:gold;
    cursor: pointer;
    padding-bottom: 8px;
  }
}