@import "../../../assets/css/Variables.New.scss";

.container {
  // table {
  //   font-size: $fontsize_large;
  // }
  .detail {
    font-size: $fontsize_regular;
  }
  .twirl {
    background-color: $color_secondary;
    color:white;
    display: inline-block;
    user-select: none;
    padding:0 4px 0 4px;
    text-align: center;
    font-size: $fontsize_regular;
    min-width: 18px;
    width: 18px;
    border:1px solid white;
    border-radius: 3px;
    cursor: pointer;
  }
  .details {
    padding: 4px 4px 4px 20px;
    table {
      td:first-child {
        color: $color_secondary;
      }
      font-size: $fontsize_regular;
    }
  }
}