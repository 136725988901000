@import "../../assets/css/Variables.New.scss";

.list {
  display: flex; flex-direction: column;
  padding: 4px;
}

.item {
  padding: 0; //4px 0 4px 0;
  cursor: move;

  .item_inner {
    border: 1px solid silver;
    padding: 4px;

    background-color: white;
  } 
}
.item.dragging {
  opacity: 0.1;
}
.item.disabled {
  opacity: 0.1;
  cursor: wait;
}

.target {
  padding:10px 0 0 0;
}
.target.hover {
  background-color: $color_purple_50;
}