@import "../../../src/assets/css/Variables.New.scss";

.container {
  position: relative;
  margin-top: 1px;
  margin-bottom: 4px;
  input {
    padding:4px;
    width:100%;
    font-size:$fontsize_large;
    font-family: $font_family;
  }
  input:focus {
    outline:solid $color_primary 1px;
  }
}