@import "./../../../assets/css/Variables.New.scss";

.container {
  div.title {
    padding:4px 2px 4px 2px;
    color:$color_green;
    font-size: $fontsize_regular;
  }
  div.filter {
    input[type=text] {
      outline: none;
      width: 300px;
      max-width: 100%;
    }
  }
  div.list {
    padding:2px 0 8px 0;
    div.item {
      padding:2px 0 0 0;

      span.title {
        font-size: $fontsize_regular;
        padding:0 3px 0 3px;
        a {
          text-decoration: underline;
          color: #333333;
        }
      }
      span.button {
        font-size: $fontsize_regular;
        border-radius: 50%;
        color:white;
        cursor: pointer;
        display: inline-block;
        $d:18px;
        max-width:$d;
        min-width:$d;
        max-height:$d;
        min-height:$d;
        text-align: center;
        outline: none;
        user-select: none;
      }
      span.button.add {
        background-color: $color_green;
      }
      span.button.remove {
        background-color: red;
      }
    }
  }

}