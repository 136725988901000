@import "./../../../assets/css/Variables.New.scss";

div#v-live {
  span.user-circle {
    display:inline-block;
    width:32px;
    height:32px;
    min-width: 32px;

    text-align: center;
    overflow: hidden;
    border-radius: 50%;
    color:white;
    font-size: $fontsize_regular;
    padding-top:6px;
    user-select: none;
  }

  .when {
    font-family: 'Roboto Mono', monospace;
    font-size: $fontsize_small;
  }

  div.marketing-flag {
    font-size: $fontsize_small;
    span.text {
      padding-left:4px;
    }
    span.flag {
      padding:0 2px 0 2px;
      color:white;
    }
    span.flag.yes {
      background-color: $color_green;
    }
    span.flag.no {
      background-color: $color_red;
    }
  }

  span.user-circle.small {
    width:16px;
    height:16px;
    min-width: 16px;
    font-size: 6px;
    padding-top:4px;
  }

  div#v-live-meta {
    padding:4px 4px 8px 4px;
    span.when {
      padding-left:4px;
    }
  }

  div#v-live-alerts {
    padding:4px 4px 8px 4px;
    div.title {
      font-size: $fontsize_regular;
      color: #333333;
    }

    div.alert {

      span.when {
        padding:0 8px 0px 4px;
        display: inline-block;

      }
      span.when.inactive {
        color:$color_disabled;
      }
      a.step {
        color:$color_green;
        text-decoration: none;
        font-size: $fontsize_small;
      }
      a.step.inactive {
        color:$color_disabled;
      }
    }
  }

  div#v-live-content {
    display: flex;
    flex-direction: row;

    div#v-live-surprises {
      padding:0 0 16px 0;

      div.surprise {
        display: flex;
        flex-direction: row;
        margin-bottom:4px;
        span.user-circle {
          cursor: pointer;
        }
        div.info {
          padding:0 4px 0 4px;
          a {
            padding:0;
            color:$color_green;
            text-decoration: none;
          }
          div.link-admin {

          }
          div.start-and-link-live {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            div.start {
              padding-top:2px;
              display: inline-block;
              font-size: $fontsize_small;
            }
            div.link-live {
              display: inline-block;
              padding-left:3px;
              a {
                padding:0;
                font-size: $fontsize_small;
              }
            }
          }

        }

        div.steps {
          display:inline-block;
          padding:8px 4px 0 4px;
          span.step-circle,
          span.step-circle-filled {
            display: inline-block;
            width:16px;
            height:16px;
            border-radius: 50%;
            border:1px solid $color_green;
            position:relative;
            span.step-circle-current {
              position: absolute;
              display:inline-block;
              width:6px;
              height:6px;
              top:4px;left:4px;
              border-radius: 50%;
              background-color: white;
            }
          }
          span.step-circle-filled {
            background-color: $color_green;
          }
        }
      }

    }

    div#v-live-events {
      div.event {
        display:flex;
        justify-content: space-between;
        flex-direction: row;
        margin:2px 0 2px 0;
        padding:4px;
        background-color: white;
        border:none;
        box-shadow: 1px 1px 2px silver;
        div.content {
          padding:0 4px 0 4px;
          width:100%;
          .what {
            img {
              height:100px;
              width: auto;
            }
          }
          .when {
          }

        }

        div.icon {
          max-width: 32px;
          min-width: 32px;
          height:32px;
        }

      }
    }
  }


  @media screen and (max-width: 768px) {
    div#v-live-content {
      display:flex;
      flex-direction: column;
    }
  }

}