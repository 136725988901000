.heading {
  display: flex; flex-direction: row; justify-content: space-between;
  padding-bottom: 32px;
  gap: 16px;

  .actions {
    display: flex; flex-direction: column;
    align-items: flex-end;
    gap: 4px;
  }


}
