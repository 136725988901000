@import "../../../../assets/css/Variables.New.scss";


div.c-surprise-condition {
  display:flex;
  flex-direction: row;
  margin-bottom:8px;

  div.actions {
    display: flex;
    flex-direction: row;
    padding:2px 2px 2px 0;

    span.upDown,
    span.upDownDisabled {
      width:20px;
      min-width: 20px;
      height:20px;
      min-height: 20px;
      border-radius: 50%;
      background-color: $color_green;
      color:white;
      text-align:center;
      padding-top: 2px;
      font-size: $fontsize_regular;
      margin-top:1px;
      margin-right:2px;
      cursor:pointer;
    }

    span.upDownDisabled {
      background-color: $color_disabled;
      cursor:not-allowed;
    }

    span.icon {
      padding-right:4px;
      color:$color_green;
      font-size: $fontsize_regular;
    }
  }

  div.text {
    width: 100%;
    padding-top:1px;
    input[type=text] {
      width: 100%;
      border:1px solid #eeeeee;
      padding:4px;
      outline: none;
    }
    input[type=text]:focus {
      border-bottom: 1px solid $color_green;
    }
  }
}