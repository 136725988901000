@import "../../../assets/css/Variables.New.scss";

table.container {
  font-size: $fontsize_regular;
  th {
    text-align: left;
  }
  td {
    padding: 2px 4px 2px 4px;
  }
  margin-bottom: 32px;
}