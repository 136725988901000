@import "../../../../assets/css/Variables.New.scss";

div#v-regionvariant-status {
  table {
    cursor: pointer;
    font-size: $fontsize_regular;
    tr.active {
      td:first-child {
        color:$color_primary;
      }
    }
    tr.inactive {
      td {
        color: #666666;
      }
    }
    td {
      padding:4px;
    }
    td:last-child {
      color:$color_primary;
    }
  }
}