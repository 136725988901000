@import "./../../../assets/css/Variables.New.scss";

.container {
  div.link {
    font-size: $fontsize_regular;
    padding: 4px 0 4px 0;
    display: block;
  }
  a {
    cursor: pointer;
    color:$color_text !important;
    text-decoration: underline;
    text-decoration-color: $color_green;
    font-size: $fontsize_regular;
  }
  a:hover {
    text-decoration: underline;
    color: $color_green !important;
  }
}