@import "./../../../../assets/css/Variables.New.scss";


.console {
  padding-top: 16px;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}

.container  {
  max-width: 800px;
  background-color: white;
  padding:8px;

  table {
    width:100%;
    border:0; margin:0; padding:0;
    border-collapse: collapse;
    td {
      vertical-align: top;
    }
    td:first-child {
      //width:33%;
      padding-right:8px;
    }
    td:nth-child(2) {
      width:100%;
    }
  }

  input, select, textarea { outline: none}

  input[type=text] {
    width:100%;
    border:1px solid silver;
  }
  input[type=text]:focus {
    border-bottom:1px solid $color_green;
  }

}