.container {
  display: flex; flex-direction: row;
  justify-content: center; align-items: center;
  height: 100vh;
  .inner {
    display:flex; flex-direction: column;
    width: 328px; height:auto;
    padding: 64px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    justify-content: center;

    .info, .logo {
      text-align: center;
      margin-bottom: 16px;
    }

    .info {
      color: white;
    }

    // .logo {
      
    // }

    .error {
      margin-top:16px; padding: 8px;
      background-color: red;
      color: white;
      text-align: center;
    }
  }
}