@import "../../../src/assets/css/Variables.New.scss";

.container {
  .label {
    //color: $color_primary;
    color: $color_label;
    padding-bottom: 2px;
  }
  .explanation {
    color: $color_secondary;
    font-size: $fontsize_regular;
    padding-bottom: 2px;
  }
}