@import "../../../src/assets/css/Variables.New.scss";

.inner {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  height:20px;
  font-size: $fontsize_small;
  line-height: $fontsize_small;
  overflow: hidden;
  border-radius: 4px;
  transition: all 0.3s ease-out;

  .main, .yes, .no {
    transition: all 0.3s ease-out;
  }
  .main {  
    padding:0 2 * $fontsize_small 0 2 * $fontsize_small; // 28px;
    white-space: nowrap;
    color: white;
  }
  .yes, .no, .cog {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    position: absolute;
    width:20px;
    height: 20px;
    border-radius: 4px;
  }
  .yes {
    left:-30px;
    color: white;
    background-color: $color_yes;
  }
  .no {
    right:-30px;
    color: white;
    background-color: $color_no;
  }
  .cog {
    left:-30px;
    color: white;
  }
}


// size: medium
.inner.medium {
  height: 30px;
  font-size: $fontsize_regular;
  line-height: $fontsize_regular;

  .main {
    padding: 0 2 * $fontsize_regular 0 2 * $fontsize_regular;
  }
  .yes,
  .no {
    width: 30px;
    height: 30px;
  }
  .cog {
    //background-color: beige;
    position: absolute;
    width: 30px;
    height: 30px;
  }
}


// intents
.inner.intent_confirm {
  background-color: $color_yes;
  .main {
    background-color: $color_yes;
    color:white;
  }
}
.inner.intent_confirm_inverted {
  background-color: white;
  border:1px solid $color_yes;
  .main {
    background-color:white;
    color: $color_yes;
  }
}
.inner.intent_delete {
  background-color: $color_no;
  .main {
    background-color: $color_no;
  }
}

// button is active (i.e. user is asked to confirm)
.inner.active {
  background-color: $color_disabled;
  .main {
    background-color:$color_disabled;
  }
  .yes, .no {
    opacity: 1;
  }
  .yes {
    left:0;
  }
  .no {
    right: 0;
  }
}

// button is disabled
.inner.disabled {
  background-color: $color_disabled;
  .main {
    background-color: $color_disabled;
  }
  .yes,
  .no {
    opacity: 0;
  }
}

// button is busy
.inner.busy {
  background-color: $color_disabled;
  .main {
    background-color: $color_disabled;
  }
  .yes,
  .no {
    opacity: 0;
  }
  .cog {
    opacity: 1;
    left:0;
    animation: keyframes_confirmation_busy 1s infinite;
  }
}

@keyframes keyframes_confirmation_busy {
  100% {transform:rotate(360deg);}
}