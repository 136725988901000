@import "../../assets/css/Variables.New.scss";

.alert {
  //margin-bottom: 8px;
  margin: 2px;
  border:1px solid $color_text;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  background-color: white;

  .left {
    text-align: center;
    color:white;
    padding:8px;
    .icon {

    }
  }

  .right {
    color:$color_text;
    padding:8px;

    .title {
      font-weight: 400;
      text-align: left;
    }

    .text {
      font-weight: 200;
      text-align: left;
    }
  }
}

// Intents
.alert.warn,
.alert.warning {
  border-color:coral;
  .left {
    background-color: coral;
  }
}
.alert.error {
  border-color:$color_error;
  .left {
    background-color: $color_error;
  }
}
.alert.info {
  border-color:royalblue;
  .left {
    background-color: royalblue;
  }
}
.alert.hint {
  border-color:rgb(250, 203, 115);
  .left {
    background-color: rgb(250, 203, 115);
  }
}


// Sizes
.alert.small {
  font-size: $fontsize_small;
  .left {
    width:30px;
  }
}
.alert.medium {
  font-size: $fontsize_regular;
  .left {
    width:40px;
  }
}
.alert.large {
  font-size: $fontsize_large;
  .left {
    width:50px;
  }
}