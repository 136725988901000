@import "./../assets/css/Variables.New.scss";

#aat-v-sidebar.staging {
  .aat-v-sidebar-header {
    background-color: $color_warning;
  }
  /*
  .menu {
    background-color: rgb(56, 37, 1);
    .aat-sidebar-section {
      .aat-sidebar-section-header {
        background-color: rgb(56, 37, 1);
      }
    }
    
  }
  */
}

#aat-v-sidebar {

  width: $sidebar_width;
  max-width: $sidebar_width;
  user-select: none;
  .aat-v-sidebar-header {
    background-color: $color_sidebar_background;
    position:relative;

    .aat-v-sidebar-header-logo {
      padding: 4px 4px 4px 4px;
      text-align: center;
      img {
        width:60%;
      }
    }

    .aat-v-sidebar-header-toggle {
      display: none;
    }
  }

  .menu {
    padding:8px 4px 32px 4px;
    background-color: $color_sidebar_background;

    .aat-sidebar-section {
      padding:0 0 16px 0;

      .aat-sidebar-section-header {
        padding:0 0 4px 4px;
        background-color: $color_sidebar_background;
        color:$color_sidebar_foreground_heading;
        font-size: $fontsize_regular;
        text-transform: uppercase;
        cursor:pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .aat-sidebar-section-header-text {}
        .aat-sidebar-section-header-toggle {
          border-radius: 1px;
          color:$color_sidebar_background;
          background-color: $color_sidebar_foreground_heading;
          padding:0 2px 0 2px;
          min-width: 12px;
          text-align: center;
        }
      }
      .aat-sidebar-section-item,
      .aat-sidebar-section-subitem {
        font-size: $fontsize_regular;
        position: relative;
        padding:2px 8px 0 4px;
        a {
          color:$color_sidebar_foreground_item;
          text-decoration: none;
          color:white !important;
        }
      }
      .aat-sidebar-section-subitem {
        font-size: $fontsize_regular;
        padding-left:10px;
        line-height: 1em;
      }
      .aat-sidebar-additionals {
        padding:4px 8px 0 4px;
        color:silver;
        cursor: pointer;
        font-size: $fontsize_smallest;
      }
    }

    .environmentinfo {
      color:$color_sidebar_foreground_item;
      padding:16px 4px 16px 4px;
      font-size: $fontsize_small;
      a {
        color:$color_sidebar_foreground_item;
      }
    }
  }



  // smaller screens
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    .menu.hidden {
      display:none;
    }

    .aat-v-sidebar-header {
      .aat-v-sidebar-header-logo {
        img {
          height:40px;
        }
      }
      .aat-v-sidebar-header-toggle {
        display:inline-block;
        position:absolute;
        top:16px;
        left:8px;

        /* header hamburger ****************************************/
        /* stolen from: https://codepen.io/designcouch/pen/Atyop ***/

        div.hamburger {
          display:inline-block;
          user-select: none;
          width: 20px; /* 60px */
          height: 15px; /* 45px */
          position: relative;
          margin: 1px 1px 1px 1px !important; /* 50px */
          transform: rotate(0deg);
          transition: .5s ease-in-out;
          cursor: pointer;

          span {
            display: block;
            position: absolute;
            height: 3px; /* 9px */
            width: 100%;
            background: $color_green;
            border-radius: 3px; /* 9px */
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
          }

          span:nth-child(1) {
            top: 0;
          }

          span:nth-child(2) {
            top: 6px; /* 18px */
          }

          span:nth-child(3) {
            top: 12px; /* 36px */
          }

        }


        div.hamburger.open span:nth-child(1) {
          top: 6px; /* 18px */
          transform: rotate(135deg);
        }

        div.hamburger.open span:nth-child(2) {
          opacity: 0;
          left: -20px; /* -60px */
        }

        div.hamburger.open span:nth-child(3) {
          top: 6px; /* 18px */
          transform: rotate(-135deg);
        }
      }
    }
  }

}