@import "../../assets/css/Variables.New.scss";

.container {
  position: relative;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 2px 4px 2px 4px;
  display: inline-flex; flex-direction: row; align-items: center;

  color: white;
  background-color: $color_error;
  border-radius: 4px;

  font-size: $fontsize_small;

  .icon {
    padding-right: 2px;
  }
  .message {

  }
}
.container:after {
  $size: 8px;
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: $color_error transparent;
  border-width: 0 $size $size;
  top: -$size;
  left: 14px; // 50%;
  margin-left: -$size;
}