@import "./../../../../assets/css/Variables.New.scss";
.mark1, 
.mark2 {
  color: white;
  padding:0 2px 0 2px;
}
.mark1 {
  background-color: $color_primary;
}
.mark2 {
  background-color: $color_secondary;
}