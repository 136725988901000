@import "../../../assets/css/Variables.New.scss";

.container {
  display: flex; flex-direction: row; justify-content: center;
  padding: 8px;
  .inner {
    max-width: 80%;
    text-align: center;
    .quote {
      position: relative;
      
      padding:8px;
      border-radius: 4px;
      border:1px solid $color_purple_50;
      background-color: white;
      .quote_text {
        padding-top: 4px;
        font-size: $fontsize_regular;
      }
    }
    .quote::before {
      content: '';
      width: 10px; height: 10px;
      border: 1px solid $color_purple_50;
      border-top: none;
      border-left: none;
      background-color: white;
      bottom: -6px;
      left: 20px;
      transform: rotate(45deg);
      position: absolute;
    }
    .name_and_activities {
      color: $color_purple;
      padding: 8px 0 0 20px;
      text-align: left;
      font-size: $fontsize_small;
      a {
        color: inherit !important;
        text-decoration: none;
      }
    }
  }
  
}