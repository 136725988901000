@import "./../../../../assets/css/Variables.New.scss";

.container {
  font-size: $fontsize_small;
  .activity {
    
    border: 1px solid silver;
    padding: 4px;
    margin: 2px 0 2px 0;
    table {
      width: 100%;
      td {
        font-size: $fontsize_regular !important;
      }
      td:last-child {
        text-align: right;
      }
      td.warning {
        background-color: $color_warning;
        color:white;
        text-align: center;
        padding:4px;
      }
    }

  }
}