@import "../../../../assets/css/Variables.New.scss";

.availability {
  table {
    padding-right:16px;
    cursor: pointer;
    tr.labels, tr.icons {
      td {
        text-align: center;
      }
    }
    tr.labels {
      font-size: $fontsize_large;
      td {
        padding: 4px;
      } 
    }
    tr.icons {
      td {
        font-size: $fontsize_small;
        padding:2px 0 2px 0;
        border: 1px solid $color_primary;
        height: 24px;
      }
      td.active {
        background-color: $color_primary;
        color: white;
      }
    }
  }
}

.edit {
  padding: 8px;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}