@import "../../../../assets/css/Variables.New.scss";

tr.hidden {
  display:none;
}
tr.warning {
  td {
    color:$color_warning;
  }
}
div.actions {
  padding-bottom: 8px;
}
.edit {
  color: $color_primary;
  cursor: pointer;
  user-select: none;
}