@import "./../../../../assets/css/Variables.New.scss";

div.appentura-surprise-bookingrequest {
  min-width: 400px;
  background-color: white;
  padding:0;

  input, select, textarea { outline: none}


  div.console {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
  }

  div.editor, div.providers {
    div.title {
      color:$color_green;
      padding:4px 0 2px 0;
    }
    div.explanation {
      font-size: $fontsize_smallest;
      padding:0 0 2px 0;
    }
  }

  div.editor {
    div.booking-kind {
      background-color: white;
      color:black;
      padding:8px 8px 4px 8px;
      font-size: $fontsize_large;
      span {
        padding-right:4px;
      }
      a {
        text-decoration: underline;
        color:$color_green;
      }
    }

    div.provider-notes,
    div.booking-info {
      padding:2px 8px 2px 8px;
      font-size: $fontsize_small;
      font-weight: 100;
      color:#333333;
    }

    div.booking-form {
      padding:8px 8px 0 8px;

      input[type=text] {
        width:100%;
        border:1px solid silver;
      }
      input[type=text]:focus {
        border-bottom:1px solid $color_green;
      }

      textarea {
        width:100%;
        border:1px solid silver;
      }

      div.services {
        table {
          width: 100%;
          border:0; margin:0; padding:0;
          border-collapse: collapse;
          tbody {
            tr {
              td:first-child {
                width:50%;
              }
              td:nth-child(2) {
                width:50%;
              }
              td:last-child {
                vertical-align: middle;
              }
            }
            tr.inactive {
              td {
                input[type="text"] {
                  background-color: whitesmoke;
                }
              }
            }
          }

        }
        div.services-console {
          padding:2px 0 2px 0;
          text-align: right;
        }

      }
    }

    div.console {
      padding:8px 8px 8px 8px;
    }
  }


  div.regions {
    padding:8px;
  }

  div.providers {
    padding:8px;
    div.color-rating {
      display:inline-block;
      width:12px;
      height:12px;
      border-radius: 50%;
      cursor: pointer;
      margin-right:1px;
      margin-top:1px;
      background-color: silver;
    }
    div.color-rating.red {
      background-color: red;
    }
    div.color-rating.orange {
      background-color: orange;
    }
    div.color-rating.green {
      background-color: $color_green;
    }

    table {
      margin-bottom: 16px;
      tr {
        td {
          vertical-align: top;
          a {
            color:$color_green;
            text-decoration: underline;
          }
          div.provider-notes {
            font-weight: 100;
            color: #222222;
          }
        }
        td.action {
          a, span.action {
            cursor:pointer;
            border:1px solid $color_green;
            border-radius:4px;
            padding:0 4px 0 4px;
            text-decoration: none;
            color:$color_green;
            white-space: nowrap;
          }
        }
      }
    }

    div.addProvider {
      display:inline-block;
      cursor:pointer;
      border:1px solid $color_green;
      border-radius:4px;
      padding:0 4px 0 4px;
      text-decoration: none;
      color:$color_green;
    }
  }

  div.no-region-warning {
    color:$color_warning;
    padding:8px;
  }

  div.provider-creator {
    padding:8px;
  }

  div.paymentTypeText {
    padding-bottom: 8px;
    label {
      font-size: $fontsize_regular;
      display:block;
      padding-bottom: 4px;
    }
  }

  div.webCopyPaste {
    padding: 8px;
    label {
      font-size: $fontsize_regular;
      display:block;
      padding-bottom: 4px;
    }
    div.clipboardtext {
      background-color: whitesmoke;
      padding:4px;
      p {
        font-size: $fontsize_small;
        padding:2px 0 2px 0;
        margin:0;
      }
    }

  }

  label.secondary {
    color:black;
    span {
      font-size: $fontsize_small;
      padding-left:4px;
    }
  }

}