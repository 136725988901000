@import "./assets/css/Variables.New.scss";

.container {
  position: fixed;
  left:0; bottom: 0;
  margin: 4px;
  padding:16px;
  background-color: $color_green;
  color:white;
  z-index: 9999999;
  cursor: pointer;
  border-radius: 8px;

  .heading {
    display: flex; flex-direction: row;
    font-size: $fontsize_large;
    padding-bottom: 8px;
  }
  .subheading {
    font-size: $fontsize_regular;
    padding-bottom: 8px;
  }
}
