@import "./../../../assets/css/Variables.New.scss";

div#v-processincidents-item {
  
  table.rating {
    font-size: $fontsize_regular;
    td:first-child {
      color: $color_primary;
    }
    margin-bottom: 8px;
  }
  div.stars {
    color:gold;
  }
  table.incidents {
    font-size: $fontsize_regular;
    margin-bottom: 8px;
    thead {
      color: $color_primary;
    }
    td {
      padding-left:8px;
    }
    td:first-child {
      padding-left:0;
    }
  }
}

div#v-processincidents-finalize {
  
}
