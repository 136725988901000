@import "./../../../../assets/css/Variables.New.scss";


.container {
  padding-bottom: 16px;

  .uppercase {
    text-transform: uppercase;
  }
  table {
    td {
      vertical-align: top;
      font-size: $fontsize_regular !important;
    }
    td.label {
      color:$color_green;
    }
    td.uppercase {
      text-transform: uppercase;
    }
    span.edit {
      padding-left:4px;
      color:$color_green;
      cursor: pointer;
    }
  }
  .ready_issues {
    margin:2px;
    padding: 4px;
    background-color: $color_warning;
    color:white;
  }
}
 