@import "../../../../assets/css/Variables.New.scss";

div#v-surprise-steps {
  div.standard-actions {
    margin:4px 0 4px 0;
    text-align: center;
  }
  div.move-actions {
    margin:8px 0 0 0;
    text-align: center;
  }
}

div.c-surprise-step-selecttemplate,
div.c-surprise-step-selectadventure {
  div.search-text {
    margin-bottom: 4px;
    input[type=text] {
      width: 100%;
      border: 1px solid silver;
      border-bottom: 1px solid $color_green;
      font-size: $fontsize_regular;
      padding:4px !important;
      outline: none;
    }
  }
  div.search-result {
    max-height: 200px;
    overflow-y: scroll;
    padding: 4px 8px 4px 4px;
    border: 1px solid silver;
    border-radius: 4px;
    margin-bottom: 4px;
  }
  div.console {
    text-align: center;
  }
}
div.c-surprise-step-selectadventure {
  div.search-text {
    display: flex;
    flex-direction: row;
  }
}