@import "./../../../../assets/css/Variables.New.scss";

.items {
  background-color: $color_purple_10;
  padding: 4px;
}
.items.dragging {
  background-color: $color_purple_50;
}

.preview {
  .tiles {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    .tile {
      padding: 4px;
      display: inline-block;
      max-width: 200px;
      text-align: center;
      .icon {
        font-size: $fontsize_regular;
      }
      .title {
        font-size: $fontsize_small;
        color: $color_secondary;
      }
      .text {
        font-size: $fontsize_small;
        font-weight: 100;
      }
    }
  }
}