@import "./../../../../assets/css/Variables.New.scss";


div#v-assistant-bookingrequests {
  padding-bottom: 16px;
}

 

div#v-assistant-bookingrequests {
  display:inline-flex;
  flex-direction: column;
  align-items: stretch;
 
  a.uppercase {
    text-transform: uppercase;
  }

  div.assistant-bookingrequests-activity {
    //display:inline-block;
    padding:4px;
    border:1px solid silver;
    margin-bottom: 4px;

    table {
      width: 100%;
      tr {
        td {
          vertical-align: top;
          padding-top:2px;
        }
        td:last-child {
          padding-top:0;
          text-align: right;
        }
      }
    }
  }
}

