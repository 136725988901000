@import "./assets/css/Variables.New.scss";

html, body, #app, #app-container {
  height:100%;
  background-color: $color_sidebar_background;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


* {
  box-sizing: border-box;
  margin:0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

table {
  td {
    vertical-align: top;
  }
  td.label {
    color: $color_primary;
  }
  td.label-secondary {
    color: $color_secondary;
  }
}

address {
  font-style: normal;
}

/** common classes **/
.centered {
  text-align: center;
}