@import "../../../../assets/css/Variables.New.scss";

.accepted {
  color:$color_green;
}
.declined {
  color:$color_red;
}
.textButton {
  cursor: pointer;
  text-decoration: underline dotted $color_green;
}
.textButton:hover {
  text-decoration: underline solid $color_green;
}

tr.bookingRequest {
  td {
    border-top: 1px dotted $color_purple_50;
    padding-top: 4px;
  }
}