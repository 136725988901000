@import "../../../src/assets/css/Variables.New.scss";

.container {
  position: relative;
  input {
    display:block
  }
  input[type=text] {
    margin: 2px;
    width: 100%;
    display: block;
    padding: 4px;
    font-size: $fontsize_large;
  }
  input[type=text]:focus {
    outline:solid $color_primary 1px;
  }
  img {
    max-height: 128px;
    width: auto;
    border: 2px solid white;
  }
  
  input[type=file] {
    background-color: $color_primary;
    width: 0.1px;
    height: 0.1px;
    opacity:0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  label.upload {
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    color: white;
    background-color: $color_primary;
    font-size: $fontsize_regular;
    font-weight: 100;
    border:2px solid white;
    padding: 4px 8px 4px 8px;
    border-radius: 8px;
  } 
}