@import "../../../../../assets/css/Variables.New.scss";

div.c-surprise-contextual-review {
  padding: 8px;
  text-align: center;
}

div.c-surprise-contextual-review-details {
  font-size: $fontsize_smallest;
  color: #222222;
  padding-bottom: 4px;
}
div.c-surprise-contextual-review-quote {
  div.title {
    color: #333333;
  }
  div.text {
    color: #222222;
  }
}