@import "../../../../assets/css/Variables.New.scss";

div.c-step-move {
  display:flex;
  flex-direction: row;
  padding: 2px;
  margin: 3px 0 3px 0;
  box-shadow: 0 0 2px silver;
  
  div.title {
    color:$color_green;
    width: 100%;
    font-size: $fontsize_large;
    padding: 6px 8px 4px 8px;
  }
  span.actions {
    display:flex;
    flex-direction: row;
    .upDown {
      $w:20px;
      width: $w;
      min-width: $w;
      height: $w;
      min-height: $w;
      border-radius: 50%;
      background-color: $color_green;
      color:white;
      text-align:center;
      padding-top: 2px;
      font-size: $fontsize_regular;
      margin: 1px;
      cursor:pointer;
    }
  }

  span.index {
    text-align: center;
    border:1px solid $color_text;
    color:$color_text;
    border-radius: 50%;
    $w:20px;
    width: $w;
    min-width: $w;
    height: $w;
    min-height: $w;
    font-size: $fontsize_regular;
    padding-top:1px;
  }

}