@import "../../../../assets/css/Variables.New.scss";

div.c-step-section {
  border: 1px solid silver;
  padding: 4px;
  border-radius: 4px;
  margin: 3px 0 3px 0;
  div.section-title {
    font-size: $fontsize_regular;
    color:$color_green;
    padding-bottom: 2px;
  }
}