@import "./../../../../assets/css/Variables.New.scss";

.container {
  display: inline-block;
  padding:2px;
  cursor: pointer;
  .inner {
    padding: 1px 3px 1px 3px;
    font-size: $fontsize_small;
    background-color: $color_primary;
    color: white;
    border-radius: 2px;
  }
}