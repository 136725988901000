@import "./../../../../assets/css/Variables.New.scss";

div.c-provider-occupancy {

  div.details {
    //margin-left:4px;
    padding:2px;
    //border:1px solid silver;
    font-weight: 100;

    table {
      margin:0 !important;
      td.green {
        color:$color_green;
      }
      td.red {
        color:$color_red;
      }
      td.yellow {
        color:$color_warning;
      }
    }
  }

}