#v_esr table.result {
    border-collapse: collapse;
    border:1px solid #f6f6f6;
    width:100%;
}

#v_esr table.result td {
    padding:2px;
    border:1px solid #f6f6f6;
}
#v_esr table.result tr.ok td {
    color:black;
}

#v_esr table.result tr.error td {
    color:red;
}

#v_esr div.action {
    padding:8px 0 8px 0;
}