div.c-outline-trip {
  position: relative;
  display:flex;
  flex-direction: row;
  //padding:4px 4px 4px 0px;
  margin-bottom: 4px;
  border:1px solid #f7f7f7;
  background-color: white; // #f7f7f7;
  div.trip-content {
    padding:4px;
    span.route-warning {
      padding-left:4px;
      color:orange;
    }
  }
  div.trip-invalid {
    display: none;
    
  }
}

div.c-outline-trip.invalid  {
  div.trip-invalid {
    display:flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    left:0;right:0;
    width:100%;height:100%;
    background-color: rgba($color: (#ffffff), $alpha: 0.8);
    
    
    div.trip-invalid-inner {
      text-align: center;
      display: block;
      background-color: orange;
      color:white;
      padding:4px;
      height: 24px;
      width: 100%;
    }
  }
}