.container {
  display: flex; flex-direction: row;
  padding: 8px;
  .selected,
  .available {
    width: 50%;
    overflow-y: auto;
    .item {
      display: flex; flex-direction: row; align-items: top;
      padding: 4px 0 4px 0;
    }
  }

  .selected {
    .item {
      justify-content: flex-end;
      .label {
        padding-right: 4px;
        text-align: right;
      }
    }
  }

  .available {
    .item {
      justify-content: flex-start;
      .label {
        padding-left: 4px;
      }
    }
  }
  
}