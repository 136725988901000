@import "../../../src/assets/css/Variables.New.scss";

.container {
  position: relative;
  margin: 1px 1px 4px 1px;

  input {
    padding:5px 4px 5px 4px;
    width:100%;
    font-size:$fontsize_large;
    font-family: $font_family;
    margin-bottom: 2px;
    border: 1px solid silver;
    border-radius: 2px;
    outline: none;
  }

  input:focus {
    border: 1px solid $color_primary;
  }
}