.container {
  font-family: monospace;
  border-left:2px solid silver;
  background-color: #eeeeee;
  padding: 4px;
  margin:0 0 4px 0;
}

.inline {
  display: inline-block;
  border:0;
}