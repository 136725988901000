.container {
  display: flex; flex-direction: row;
}
.left {
  justify-content: start;
}
.center {
  justify-content: center;
}
.right {
  justify-content: end;
}
.xstart {
  align-items: flex-start;
}
.xend {
  align-items: flex-end;
}
.xcenter {
  align-items: center;
}