@import "./../../../assets/css/Variables.New.scss";

.container {
  padding-top: 8px;

  .items {
    background-color: $color_purple_10;
    padding: 4px;
  }
  .items.dragging {
    background-color: $color_purple_50;
  }
}

/**/
.item {
  background-color: white;
  padding:16px 8px 16px 8px;
  position: relative;
  margin:14px 0 0 0;
  .actions {
    opacity: 1;
    display: flex; flex-direction: row;
    align-items: center;
    position: absolute;
    top:-10px;
    .code,
    .countries {
      background-color: white;
      display:inline-block;
      font-size: $fontsize_small;
      padding:2px 5px 2px 5px;
      border-radius: 4px;
      border:1px solid $color_secondary;
      margin-left:4px;
    }
  }

}
