@import "./../../../assets/css/Variables.New.scss";

div#v-providers-list {
  div#v-providers-list-filter {
    
    padding:4px 0 4px 0;
    label {
      font-size: $fontsize_large;
      span { 
        padding-left: 4px;
      }
    }
  }
}