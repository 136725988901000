@import "../../../../assets/css/Variables.New.scss";

div#v-surprise-links {
  table {
    tr {
      td {
        vertical-align: top;
        a {
          color:$color_green;
        }
      }
    }
  }
}