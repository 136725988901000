@import "./../../../../assets/css/Variables.New.scss";

.container {
  padding-bottom: 4px;
  .label {
    color: $color_primary;
    font-size: $fontsize_small;
    padding-bottom: 2px;
  }
  .items {
    label {
      font-size: $fontsize_regular;
      padding-right: 4px;
      span {
        padding-left:2px;
      }
    }
  }
}