.container {
  display: inline-block;

  .star {
    font-size:24px;
    width: 24px !important;
    display: inline-block;
    color: gray;
  }

  .star:last-child {
    margin-right: 0 !important;
  }

  .star:before {
    content:'\2605';
  }

  .star.on {
    color: gold;
  }

  .star.half:after {
    content:'\2605';
    color: gold;
    position: absolute;
    margin-left: -24px !important;
    width: 12px !important;
    overflow: hidden;
  }
}