@import "./../../../assets/css/Variables.New.scss";

div.view-resources {
  padding-top:4px;
  div.resource {
    margin-bottom:8px;
    .title {
      a {
        color:$color_green;
        font-size: $fontsize_regular;
      }

    }
    .description {
      font-size: $fontsize_regular;
    }

  }
}