@import "./../../../assets/css/Variables.New.scss";

#v-statistics {

  div.timestamp {
    display:flex;
    flex-direction: row;
    font-size: $fontsize_small;
    padding:2px 0px 8px 0px;
    div.info {
      padding:2px 4px 2px 0px;
    }
    div.button {  }
  }

  table {
    margin:0;
    border:0;
    padding:0;

    tr {
      margin:0;
      border:0;
    }
  }
  td.section-header,
  div.section-header {
    padding-top:16px;
    color:$color_green;
    font-weight: 200;
    font-size: $fontsize_large;
  }

  div.section-notes {
    padding:4px 0 8px 2px;
    color: #222222;
    font-size: $fontsize_regular;
  }

  td.value {
    text-align: right;
    padding-left:8px;
  }
  td.subheader {
    padding-top:16px;
    text-align: right;
  }
  th {
    text-align: left;
    padding:0 4px 0 4px;
  }



  select {
    border:0;
    outline: 0;
  }

  select:disabled {
    -webkit-appearance: none;
  }

  label {
    color:$color_green;
    margin-right:4px;
  }

  div.timeframe-and-groups {
    div.timeframe {
      padding-top:4px;
    }

    div.groups {
      padding:4px 0 4px 0;
      display:flex;
      flex-direction: row;
      justify-content: left;

      div.group {
        box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.1);
        div.name {
          text-align: center;
          padding:4px;
          color:white;
          font-weight: 100;
          label {
            user-select: none;
            color:white;
            padding:0px 4px 0 4px;
          }
          .etui-checkbox .checkbox .mark {
            border-color:white;
          }
        }
        div.range {
          padding: 4px;
          display:flex;
          flex-direction: row;
          div.range-item {
            padding: 4px;
          }
        }

      }
      div.group:first-child {
        margin-right:2px;
      }
      div.group:last-child {
        margin-left:2px;
      }

      div.vs {
        display:flex;
        flex-direction: column;
        align-items: center;
        padding:12px 4px 2px 4px;
      }
    }
  }

  div.chart-container {
    div.section-header {
      padding-left:2px;
    }
    select {
      margin-bottom:4px;
    }
  }


}
