div#aat-v-vendorinvoices-actions {
  padding-top:16px;
}

.vendorinvoices-postfilter-info {
  padding: 2px;
}
.vendorinvoces-select-all {
  display:flex; flex-direction: row; align-items: center;
  padding-left: 2px;
  padding-bottom: 8px;
  padding-top: 8px;
  user-select: none;
  cursor: pointer;
  span {
    padding-left: 8px;
  }
}

table#aat-v-vendorinvoices-list {
  margin-top:8px;
  td {
    vertical-align: top;
  } 
}
