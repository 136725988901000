@import "../../../assets/css/Variables.New.scss";

.container {
  padding: 4px;
  
  .info {
    font-size: $fontsize_regular;
    .count {
      font-size: $fontsize_largest;
      font-weight: $fontweight_bold;
    }
    padding-bottom: 4px;
  }
}