@import "./../../../assets/css/Variables.New.scss";

div.c-provider-rating {
  margin-bottom: 8px;
  
  div.c-provider-rating-label {
    font-size: $fontsize_regular;
    color: $color_primary;
    padding-bottom: 4px;;
  }
  div.c-provider-rating-items {
    div.c-provider-rating-item {
      display: inline-block;
      width:24px;
      height:24px;
      border-radius: 50%;
      border:1px solid;
      background-color: silver;
      margin-right:2px;
    }
    div.c-provider-rating-item.green {
      background-color: green;
      border-color: green;
    }
    div.c-provider-rating-item.orange {
      background-color: orange;
      border-color: orange;
    }
    div.c-provider-rating-item.red {
      background-color: red;
      border-color: orange;
    }

    // inactive
    div.c-provider-rating-item.inactive {
      background-color: silver;
    }

    // disabled
    div.c-provider-rating-item.busy {
      background-color: #222222;
      animation: c-provider-disabled-keyframe 2s linear infinite;
    }
  }
}

@keyframes c-provider-disabled-keyframe {
  0% { background-color: $color_primary; }
  50% { background-color: $color_secondary; }
  100% { background-color: $color_primary; }
}