@import "../../../assets/css/Variables.New.scss";

.availability {
  div.matrix {
    display: flex; flex-direction: row;
    .matrix_item {
      margin:1px;
      display: flex; flex-direction: column;
      div.label,
      div.status {
        padding: 2px;
      }
      div.label {
        font-size: 16px;
        padding-bottom: 2px;
        text-align: center;
      }
      div.status {
        display: flex; flex-direction: row;
        align-items: center; justify-content: center;
        border: 1px solid $color_primary;
        width: 26px; height: 24px;
        font-size: 16px;
        cursor: pointer;
        user-select: none;
      }
      div.status.active {
        background-color: $color_primary;
        color: white;
      }
    }
  }
}

.edit {
  padding: 8px;
  //display: flex; flex-direction: row; justify-content: center;
  .availability {
    display: flex; flex-direction: row; justify-content: center;
    padding-bottom: 8px;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}