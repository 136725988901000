@import "./../../../assets/css/Variables.New.scss";

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .activity_image {
    flex-direction: column;
    //width:180px;
    padding:8px;
    div.image {
      width:180px;
      height: 180px;
      position: relative;
      padding: 2px;
      img.image {
        width: 100%;
        height: auto;
        cursor: pointer;
      }
      div.default {
        position: absolute;
        top:8px; right: 8px;
        font-size: $fontsize_largest;
        color:$color_primary;
        padding: 4px;
        background-color: white;
        border-radius: 50%;
      }
    }
    div.blurhash {
      position: relative;
      cursor:zoom-in;
      .blurhash_small {
        padding: 2px 2px 1px 0;
        text-align: right; 
      }
      .blurhash_large {
        position: absolute;
        bottom: 26px; left:2px;
        display:none;
      }
    }
    div.blurhash:hover {
      .blurhash_large {
        display: block;
      }
    }

    div.actions {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .activity_add {
    padding:4px;
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color:$color_primary;
  }
}