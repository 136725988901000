@import "./../../../assets/css/Variables.New.scss";

.container {
  table tr td {
    padding:4px;
    font-size: $fontsize_regular;
  }
  a {
    color:$color_green;
    text-decoration: none;
    font-weight: medium;
  }
  a.hover {
    text-decoration: underline;
  }

  select {
    // TODO why do we style this in here ... should use widget
    /* reset */
    margin: 0;      
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;

    /* styling */
    background-color: white;
    border: thin solid $color_primary;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    font-size: $fontsize_large;
    line-height: 1.5em;
    padding: 2px 44px 2px 4px;
    margin-bottom: 8px;
  }

  
  
  /* arrows */
  
  select {
    background-image:
      linear-gradient(45deg, transparent 50%, white 50%),
      linear-gradient(135deg, white 50%, transparent 50%),
      linear-gradient(to right, $color_primary, $color_primary);
    background-position:
      calc(100% - 20px) calc(12px),
      calc(100% - 15px) calc(12px),
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
  }
  
  
  
  
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  
  
}