@import "../../../assets/css/Variables.New.scss";


.container {
  .inner {
    display: flex;
    flex-direction: row;

    .catalogue {
      margin: 40px 8px 0 0;
      padding: 8px;
      font-size: $fontsize_large;
      background-color: white;

      a {
        text-decoration: none !important;
      }
      .groupName {
        font-weight: 400;
        padding-bottom: 8px;
        color: $color_purple;
      }
      .itemName {
        cursor: pointer;
        font-weight: 100;
        padding-bottom: 8px;
        
      }
    }
    .component {
      padding-left:8px;
    }
  }
}