.container {
  width: 100%;
  height: 100%;
  position: relative;

  .info {
    position: absolute;
    bottom: 8px; right: 8px;
    background-color: white;
    color: black;
    border-radius: 4px;
    padding:2px 8px 2px 8px;
    font-size: 12px;
  }
  
}

.container.loading {
  animation : shimmer 2s infinite;
  background: linear-gradient(to right, #70a8e1 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}