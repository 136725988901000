@import "./../../../../assets/css/Variables.New.scss";

div.c-outline-activity {
  //padding:4px;
  margin-bottom: 8px;
  border:1px solid $color_green;
  
  div.mover-group {
    padding:4px;
  }
  span.mover {
    display: inline-block;
    text-align: center;
    font-size: $fontsize_small;
    min-width: 20px;
    background-color: $color_green;
    color:white;
    padding:2px 4px 2px 4px;
    cursor:pointer;
    border-radius: 2px;
    margin:0 1px 0 1px;
  }
  span.mover.empty {
    background-color: transparent;
  }
  display:flex;
  flex-direction: row;
  
}