@import "./../../../../assets/css/Variables.New.scss";

.container {
  a {
    color:$color_link;
  }
  table {
    td {
      font-size: $fontsize_regular;
    }
    td.number {
      text-align: right;
    }
    td.number:last-child {
      padding-left:8px;
    }
    td.total {
      border-top:1px solid;
      padding-top:4px;
    }
  }
}