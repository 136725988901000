@import "./../../../../assets/css/Variables.New.scss";

div#assisant-outline-providerselector {
  width:600px;
  max-width:90%;
  div.color-rating {
    display:inline-block;
    width:16px;
    height:16px;
    border-radius: 50%;
    background-color: silver;
    border:1px solid silver;
    cursor: pointer;
    margin-right:1px;
  }
  
  div.color-rating.green {
    background-color: green;
    border-color: green;
  }
  div.color-rating.orange {
    background-color: orange;
    border-color: orange;
  }
  div.color-rating.red {
    background-color: red;
    border-color:red;
  }
  
}