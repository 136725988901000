@import "../../../src/assets/css/Variables.New.scss";

.container {
  position:relative;
  width:100%;
  margin-bottom: 4px;
  
  input {
    padding:4px;
    width:100%;
    font-size: $fontsize_large;
    font-family: $font_family;
    margin-bottom: 2px;
    border: 1px solid silver;
  }
  input:focus {
    outline:solid $color_primary 1px;
  }

  select {
    background-color: white;
    position:absolute;
    width:100%;
    z-index:99999 !important;
    font-family: $font_family;
    font-size: $fontsize_large;

    option:active,
    option:focus {
      background-color: $color_confirm;
      color: white;
    }
  }
}