@import "../../../assets/css/Variables.New.scss";

.list {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  //background-color: aqua;
}
.filter {
  .row {
    display: flex; 
    flex-direction: row;
  }
  .row>div {
    padding-right:2px;
  }
}
.selfie {
  padding: 4px;
  .selfie_inner {
    border: 1px solid $color_purple_50;
    border-radius: 4px;
    display:flex;
    flex-direction: row;
    padding: 4px;
    font-size: $fontsize_regular;
    //background-color: orange;
    .image {
      position: relative;
      //width:240px;
      //min-height: 100px;
      //background-color: silver;
      img {
        height:158px;
        width:auto;
      }
      .bookmark {
        position: absolute;
        top: -4px; right: 8px;
        font-size: 28px;
        color: white;
        cursor: pointer;
      }
      .bookmark.active {
        color: $color_green;
      }
      .selected {
        position: absolute;
        top: 118px; right: 4px;
        font-size: 28px;
        color: white;
        cursor: pointer;
      }
      .selected.active {
        color: white;
      }
    }
    .info {
      table {
        padding:0; margin:0;
        td {
          padding: 1px;
        }
        td:first-child {
          text-align: center;
        }
      }
      max-width: 200px;
    }
    .testimonials {
      
    }
    .post {
      border: 1px solid $color_purple_50;
      border-radius: 4px;
      padding: 4px 4px 2px 4px;
      margin-bottom:2px;
    }
  }
  
}

.selectedDashboard {
  padding: 8px;
  margin: 4px 0 8px 0;
  background-color: white;
  border-radius: 4px;
  .images {

    overflow-x: scroll;
    .image {
      display: inline-block;
      margin:2px;
      position: relative;
      .delete {
        position: absolute;
        bottom:6px; right: 2px;
        display: block;
        cursor: pointer;
        font-size: 10px;
        background-color: red;
        color: white;
        padding: 2px;
        border-radius: 4px;
      }
      img {
        height: 50px;
        width: auto;
      }
    }
  }
  .summary {
    font-size: $fontsize_small;
  }
  .actions {
    display: flex; flex-direction: row;
  }
}

.options {
  padding-bottom: 4px;
}