@import "../../../../assets/css/Variables.New.scss";

div.c-step-read {
  padding:4px 4px 4px 8px;
  font-size: $fontsize_regular;
  width: 100%;
  position: relative;
  
  div.title {
    font-weight: 500;
    color:$color_green;
    padding-bottom:4px;
    font-size: $fontsize_large;
  }
  div.alert {
    font-weight: 100;
    color:#333333;
    padding-bottom: 4px;
  }
  div.instructions {
    padding-bottom: 4px;
    font-weight: 400;
    ul {
      list-style-position: outside;
      padding-left: 16px;
    }
    li {
      padding-bottom:2px;
    }
  }

  div.image {
    width: 100% !important;
    div.image-inner {
      display: inline-block;
      width: 100px;
      position: relative;
      cursor: pointer;

      img {
        width: 100% !important;
        height: auto;
      }
      .toggle {
        position: absolute;
        top: 4px;
        left: 4px;
        color: white;
        font-size: 24px;
      }
    }
  }
  div.image.large {
    div.image-inner {
      width: 100%;
    }
  }

  div.map {
    div.googleMap {
      width: 100%;
      height: 300px;
    }
    div.toggle-and-link {
      display: flex;
      width: 100%;
      flex-direction: row;
      div.map-travelmode {
        padding:4px 4px 0 0;
        font-size: $fontsize_regular;
      }
      div.map-toggle {
        padding:4px 8px 4px 8px;
        font-size: $fontsize_small;
        background-color: $color_green;
        color: white;
        border:1px solid white;
        border-radius: 8px;
        cursor: pointer; 
      }
      
      div.map-link {
        a {
          color: white;
          text-decoration: none;
        }
      }
      
    }
  }

  div.attachments {
    ul {
      list-style-position: outside;
      padding-left: 16px;
    }
    li {
      padding-bottom:2px;
    }
  }

  div.console {
    text-align: center;
  } 
}