@import "../../../../assets/css/Variables.New.scss";

div#v-surprise-activitiesfix {
  div.activityfix-subtitle {
    margin:8px 0 4px 0;
    font-size: $fontsize_large;
  }
  div.activityfix-explanation,
  div.activityfix-items {
    font-size: $fontsize_regular;
  }
  div.activityfix-items {
    padding: 8px;
  }
}
