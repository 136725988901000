div#aat-v-surprise-invoices {
  table {
    border-collapse: collapse;

    td {
      padding:2px;
      vertical-align: top;
    }

    tr.total {
      td {
        border-top:1px solid silver;
        padding-top:8px;
      }
      
    }
  }
  
}