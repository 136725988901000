@import "../../../assets/css/Variables.New.scss";

.container {
  padding:2px;
  cursor: pointer;
  user-select:none;
}
.container.disabled {
  cursor: not-allowed;
}
.container.busy {
  cursor: wait;
}