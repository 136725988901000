@import "./../../../assets/css/Variables.New.scss";

div#v-provider-location {
  min-width: 640px;
  max-width: 90%;

  div.c-provider-location-group {
    margin-bottom: 4px;
    div.title {
      color:$color_green;
    }
    div.content {
      border-left:1px solid $color_primary;
      //border-bottom: 1px solid $color_green;
      padding-left:8px
    }
  }
}