@import "./../../../assets/css/Variables.New.scss";

.container {
  margin-top:8px;
}
.list {
  
  display:flex;
  flex-direction: rows;
  flex-wrap: wrap;

  .activity_video {
    padding:4px;
    .activity_video_inner {
      iframe {
        width:280px;
        height:158px;
      }
      .activity_video_actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
  }

  .activity_video_add {
    width: 158px;
    height: 158px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    div.icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border:1px solid $color_primary;
      color:$color_primary;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      font-size: $fontsize_large;
    }
  }
}