#v-massmailer {

}
#v-massmailer section {
    padding:16px 0 16px 0;
}
#v-massmailer select {
    outline: none;
    margin:4px 0 4px 0;
}

#v-massmailer .preview .preview-item {
    padding: 4px;
    font-family: "Times New Roman" !important;
    margin:4px 0 4px 0;
    border:1px solid silver;
}

#v-massmailer label,
#v-massmailer input[type=text],
#v-massmailer textarea {
    outline: none;
    display:block;
    width:100%;
}

#v-massmailer label.inline,
#v-massmailer input[type=checkbox] {
    display: inline-block;
}