// colors
$color_text: #131516;
$color_text_secondary: #555555;

$color_red: crimson;

$color_green: rgb(84, 172, 96);
$color_green_50: rgba(84, 172, 96, 0.5);
$color_green_10: rgba(84, 172, 96, 0.1);

$color_purple: rgb(123, 73, 151);
$color_purple_50: rgba(123, 73, 151, 0.5);
$color_purple_10: rgb(244, 236, 245); /// rgba(123, 73, 151, 0.10);




// applied colors
$color_primary: $color_green;
$color_secondary: $color_purple;
$color_secondary_50: $color_purple_50;
$color_secondary_10: $color_purple_10;

$color_link: $color_green;
$color_label: $color_green;
$color_label_secondary: $color_purple;
$color_confirm: $color_green;
$color_yes: $color_green;
$color_delete: $color_red;
$color_cancel: $color_red;
$color_no: $color_red;
$color_error: $color_red;
$color_warning: #ffa500;
$color_disabled: silver;

$color_error_text: $color_red;
$color_error_surface: $color_red;
$color_error_surface_text: white;

// specific colors ... TODO should all use standard colors
$color_sidebar_background: #283441;
$color_sidebar_foreground_heading: $color_green;
$color_sidebar_foreground_item: #FFFFFF;
$color_info: blue;



// dimensions
$sidebar_width:160px;
$sidebar_width_collapsed:28px;



// font-family
$font_family:'Roboto', Helvetica, sans-serif;

// font-sizes
$fontsize_largest: 22px;
$fontsize_larger: 18px;
$fontsize_large: 16px;
$fontsize_regular: 14px;
$fontsize_small: 12px;
$fontsize_smallest: 10px;

// applied font-sizes
$fontsize_input: 16px; // textinput, textarea, dropdown, etc

// font-weights
$fontweight_light: 100;
$fontweight_regular: 200;
$fontweight_bold: 400;


