@import "../../assets/css/Variables.New.scss";

.container {
  position: relative;
  margin: 1px 1px 4px 1px;
  // css does not seem to recognize [type=datetime] or [type=datetime-local], hence the .date class name
  input.date {
    padding:4px;
    width:100%;
    font-size: $fontsize_input;
    font-family: $font_family;
  }
  input.date:focus {
    outline:solid $color_label 1px;
  }
  .nullable {
    padding-top:4px;
    //
    label {
      display:flex; flex-direction: row; // justify-content: flex-start; align-items: flex-start;
      //text-align: left;
      cursor: pointer;
      input[type=checkbox] {
        background-color: antiquewhite !important;
        display: inline !important;
      }
      span {
        padding-left: 4px;
        white-space: nowrap;
      }
    }
  }
}