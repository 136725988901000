@import "./../../../assets/css/Variables.New.scss";

div#v-provider-bookingsettings {
  table {
    td, th {
      font-size: $fontsize_large;
      padding:2px;
      vertical-align: middle;
      vertical-align: top;
    }
    th {
      color: $color_secondary;
      text-align: right;
      font-size: $fontsize_regular;
      
      font-weight: normal;
      padding:3px 8px 0 0;
      white-space: nowrap;
    }
  }
}