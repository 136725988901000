#aat-v-app {
  width: 100%;
  display:flex;
  flex-direction: row;

  // on smaller screens with stack sidebar and content
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  #aat-v-app-content {
    width: 100%;
    background-color: #eaeafa;
    padding:8px;
  }

  // sidebar is styled in Sidebar.scss
}
