@import "./../../../assets/css/Variables.New.scss";

.container {
  div.tabs {
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;

    div.tab {
      font-size: $fontsize_large;
      padding:4px 8px 4px 8px;
      user-select: none;
      cursor: pointer;
      color:#111111;
      border-bottom: 4px solid white;
      span.title {
        padding-left:4px;
      }
    }

    div.tab.current {
      border-bottom-color: $color_secondary;
    }

  }
}