@import "../../../../assets/css/Variables.New.scss";

.container {
  margin-bottom:4px;
  position: relative;
}

.containerFixed {
  position:fixed;
  top:4px;
  left:$sidebar_width + 8px;
  $right_padding:$sidebar_width + 16px;
  z-index:998;
  width: calc(100% - #{$right_padding});
}

.compact {
  padding: 4px 8px 4px 8px;
  margin: 0 4px 0 4px;
  background-color: $color_warning;
  color:white;
} 

.toggle {
  display: flex; flex-direction: column; 
  justify-content: center; align-items: center;
  width: 17px;
  height: 17px;
  font-size: $fontsize_smallest;
  position: absolute;
  right: 7px;
  top: 3px;
  border: 1px solid white;
  border-radius: 50%;
  background-color: $color_warning;
  color: white;
  cursor: pointer;

}