.container {
  padding: 4px;
}
.item {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  gap: 4px;
  
  .icon {
    width: 20px;
    font-size: 19px;
    text-align: center;
  }
  .icon.pass {
    color: #52c41a;
  }
  .icon.failed {
    color: red;
  }
  .icon.severity_1 {
    color: yellow;
  }
  .icon.severity_2 {
    color: orange;
  }
  .icon.severity_2 {
    color: red;
  }

  .text {
    display: flex;
    flex-direction: column;

    .title {
      padding-top: 2px;
      font-weight: 400;
    }
    .description,
    .hint {
      font-weight: 100;
    }
    .link {
      font-weight: 100;
    }
  }
  
  
}