@import "./../../../../assets/css/Variables.New.scss";

.table {
  padding: 2px 0 2px 0;
  font-size: $fontsize_regular;
  td:first-child {
    color: $color_primary;
    padding-right:4px;
  }
}

