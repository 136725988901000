@import "../../../src/assets/css/Variables.New.scss";

.container {
  display: inline-block;
  border:0;
  padding:0;
  margin:0;
  padding:1px;
}

// intens
.container.intent_delete,
.container.intent_cancel,
.container.intent_error {
  color: $color_no;
}
.container.intent_confirm {
  color: $color_yes;
}
.container.intent_info,
.container.intent_hint {
  color: $color_info;
}
.container.intent_warn {
  color: $color_warning;
}

// clickable
.container.clickable {
  cursor: pointer;
}
