@import "./../../../../assets/css/Variables.New.scss";

.container {
  padding: 2px;
  .heading {
    display: flex; flex-direction: row; align-items: center;
    background-color: white;
    padding: 4px;
    font-size: $fontsize_regular;
    user-select: none;
    cursor:pointer;

    .toggle {
      color: $color_secondary;
      cursor: pointer;
    }

    .title {
      padding: 0 4px 0 4px;
      text-transform: uppercase;
    }

    .baseProperties {
      display: flex; flex-direction: row; align-items: center;
      
      .basePropertyItem {
        padding:1px 4px 1px 4px;
        border: 1px dotted $color_secondary;
        border-radius: 4px;
        background-color: white;
        color: $color_secondary;
        margin:0 1px 0 1px;
      }
    }
  }

  .divider {
    height:1px;
    background-color: $color_purple_10;
    //border:1px solid $color_secondary;
  }

  .content {
    background-color: white;
    padding: 4px;
    border-bottom: 1px solid $color_purple_10;
  }
}

.container.expanded {
  
}

.container.collapsed {
  .divider {
    display: none;
  }
  .content {
    display: none;
  }
}