@import "../../../assets/css/Variables.New.scss";

.item {
  border: 1px solid $color_purple_10;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  .image {
    img {

      width: auto;
      height: 150px;
    }
  }
}