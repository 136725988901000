@import "../../assets/css/Variables.New.scss";

.inner {
  display: flex; flex-direction: row; align-items: flex-start; justify-content:flex-start;
  input[type=checkbox] {
    margin-top: 3px;
  }
  span.label {
    padding-left:4px;
    font-size: 16px;
  }
}
