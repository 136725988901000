@import "../../../src/assets/css/Variables.New.scss";

div.container {
  margin-bottom: 8px;

  .actions {
    padding-bottom: 4px;
  }

  div.search {
    padding:2px;
    margin-bottom: 4px;
    input[type=text] {
      outline: none;
      padding:2px;
      font-size: $fontsize_regular;
      width: 100%;
    }
  }
  div.footer {
    border-top: 1px solid $color_secondary;
    padding-top:8px;
    font-size: $fontsize_regular;
    color: $color_text_secondary;
    span.reset {
      cursor: pointer;
      color: $color_primary;
    }
  }

  table {
    font-size: $fontsize_large;
    background-color: white;
    margin-bottom: 4px;
    
    thead {
      color:$color_secondary;
      user-select: none;
      td {
        font-size: $fontsize_regular;
        padding: 2px 4px 2px 4px;;
        cursor: pointer;
      }
    }
    td.left {
      text-align: left;
    }
    td.center {
      text-align: center;
    }
    td.right {
      text-align: right;
    }
    tbody,
    a {
      color:$color_text;
      text-decoration: none;
      td {
        padding: 2px 4px 2px 4px;
      }
    }
    a:hover {
      text-decoration: underline;
    }
    tbody, thead {
      td:first-child {
        padding-left: 0;
      }
      td:last-child {
        padding-right: 0;
      }
    }
  }
  
  .filters {
    padding: 4px 2px 4px 2px;
    .checkbox_filter {
      label {
        display: flex; flex-direction: row; align-items: center;
        font-size: $fontsize_regular;
        cursor: pointer;
        user-select: none;
        span {
          padding-left: 4px;
        }
      }
      
    }
  }
}
