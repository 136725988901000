@import "../../../../assets/css/Variables.New.scss";

div#v-soldofflinefix {
  div.location,
  div.activities {
    padding:8px 0 8px 0;
  }
  div.console {
    text-align: center;
  }
  
  table {
    tr:first-child {
      td {
        span {
          transform: rotate(180deg); 
          writing-mode: vertical-lr;
        }
        
      }
    }
  }
}
