@import "./../../../../assets/css/Variables.New.scss";

.container {
  background-color: white;
  border:1px solid silver;

  .console {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .info,
  .form,
  .console {
    padding: 8px;
  }

  .form {
    width: 100%;
    table {
      width: 100%
    }

    .accepted_declined {
      display:flex;
      flex-direction: row;

      .accepted,
      .declined,
      .pending {
        display: flex;
        flex-direction: column;
        padding:8px;
        border:1px solid silver;
        cursor:pointer;
        text-align: center;
        color:white;
      }

      .accepted.inactive,
      .declined.inactive,
      .pending.inactive {
        background-color: silver;
      }
      .accepted.active {
        background-color: $color_green;
      }
      .declined.active {
        background-color: red;
      }
      .pending.active {
        background-color: cornflowerblue;
      }
    }
  }

}