@import "../../../../assets/css/Variables.New.scss";

.container {
  display:flex;
  justify-content: center;

  div.item {
    position:relative;
    width:80px;
    height:50px;
    overflow: wrap;
    text-align:center;
  }

  div.circle, div.text, div.line {
    display:inline;
    position:absolute;
  }

  div.circle {
    width:20px;
    height:20px;
    border-radius:50%;
    border:2px solid $color_green;
    top:4px;
    left:29px;
    background-color:white;
  }

  div.circle.selected {
    background-color:$color_green !important;
  }

  div.line {
    height:2px;
    background-color:$color_green;
    top:13px;
  }

  div.line {
    width:80px;
    left:0;
  }

  div.line.last {
    width:40px;
  }

  div.line.first {
    width:40px;
    left:40px;
  }

  div.text {
    width:80px;
    top:33px;
    left:-0px;
    font-size: $fontsize_smallest;
  }
}
