@import "../../assets/css/Variables.New.scss";

.container {
  margin: 8px 0 8px 0;
  position: relative;
  .title {
    position: absolute;
    padding: 0 2px 0 2px;
    top: -4px; left: 4px;
    background-color: white;
    font-size: $fontsize_small;
    line-height: $fontsize_small;
    color: $color_purple;
  }
  .content {
    border: 1px solid $color_purple;
    border-radius: 4px;
    padding: 8px 4px 4px 4px;
  }

}