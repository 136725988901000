@import "../../../src/assets/css/Variables.New.scss";

$border_radius: 4px;

.container {
  background-color: white;
  color: $color_text;
  margin-bottom: 8px;
  border-radius: $border_radius;

  .heading,
  .heading_collapsible {
    border-radius: $border_radius;
    background-color: $color_sidebar_background;
    color:white;
    .title {
      font-size:$fontsize_large;
      padding:8px;
    }
  }

  .heading_collapsible {
    cursor: pointer;
    
    .title_and_toggle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .title {
        padding:8px 8px 8px 8px;
      }
      .toggle {
        display:flex;
        flex-direction: column;
        justify-content: center;
        font-size: $fontsize_large;
        padding:8px 8px 8px 8px;
        color: white;
      }
    }

    .summary {
      padding: 0 8px 8px 8px;
      font-size: $fontsize_regular;
    }
  }

  .heading,
  .heading_collapsible.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .content{
    padding:8px;
    font-size: $fontsize_regular;
  }
}

.container.size_small {
  .heading,
  .heading_collapsible {
    .title {
      font-size: $fontsize_regular;
    }
  }
  .toggle {
    font-size: $fontsize_small;
  }
}

.container.intent_warning {
  border-color: aqua;
  .heading,
  .heading_collapsible {
    background-color: $color_warning;
    color:white;
  }

}