@import "../../../src/assets/css/Variables.New.scss";

.inner {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  height:20px;
  font-size: $fontsize_regular;
  line-height: $fontsize_regular;
  background-color: $color_confirm;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid $color_confirm;
  transition: all 0.3s ease-out;
  white-space: nowrap;
}

.main {  
  color:white;
  padding:0 28px 0 28px;
  transition: all 0.3s ease-out;
  position: relative;
  float: left;
  width: 100%;
}

.cog {
  //display: inline-block;
  //float: left;
  position: absolute;
  text-align: center;
  min-width:100%;
  color: white;
  display: none;
  // opacity: 0;

  .cog_inner {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    font-size: 14px;
    animation: keyframes_button_busy 1s infinite;
  }
}

/*
.container {
  padding: 2px;
  display: inline-block;
  border-radius: 4px;
  user-select:none;
  cursor: pointer;
}
*/

/* Medium (default) ******/
.inner.medium {
  height: 30px;
  font-size: $fontsize_regular;
  line-height: $fontsize_regular;
  .main {
    padding: 0 $fontsize_regular 0 $fontsize_regular;
  }
}

/* Small ******/
.inner.small {
  height: 20px;
  font-size: $fontsize_small;
  line-height: $fontsize_small;
  .main {
    padding: 0 $fontsize_small 0 $fontsize_small;
  }
}

/* Intent: ok (default) */
inner.ok,
inner.confirm {
  background-color: $color_confirm;
  border-color: $color_confirm;
}

.inner.ok_inverted,
.inner.confirm_inverted {
  background-color: white;
  .main {
    color:$color_confirm;
  }
}

/* Intent: delete, cancel **/
.inner.delete,
.inner.cancel {
  border-color: $color_cancel;
  background-color: $color_cancel;
}
.inner.delete_inverted,
.inner.cancel_inverted {
  background-color: white;
  border-color: $color_cancel;
  .main {
    color: $color_cancel;
  }
}

/* Disabled *********/
.inner.disabled {
  background-color: $color_disabled;
  border-color: $color_disabled;
  .main {
    background-color: $color_disabled;
  }
}

/* Busy *************/
.inner.busy {
  background-color: $color_disabled;
  .main {
    background-color: $color_disabled;
    filter:blur(1px);
  }
  .cog {
    display: inline-block;
    //opacity: 1;
    left:0;
  }
}

/* narrow *************/
.container.narrow {
  .main {
    padding: 0 4px 0 4px;
  }
}

@keyframes keyframes_button_busy {
  100% {transform:rotate(360deg);}
}