@import "./../../../assets/css/Variables.New.scss";

.picker {
  user-select: none;
  cursor: pointer;
  padding: 4px;
  .inner {
    border: 1px solid $color_secondary;
    border-radius: 4px;
    .title {
      font-size: $fontsize_large;
      padding: 4px;
      background-color: $color_secondary;
      color:white
    }
    .description {
      padding: 4px;
      font-size: $fontsize_regular;
    }
  }
}