@import "../../../../assets/css/Variables.New.scss";

div.c-surprise-step {
  display:flex;
  flex-direction: row;
  border:1px solid #333333;
  border-radius: 4px;
  padding: 4px;
  margin: 4px 0 4px 0;

  div.actions {
    display:flex;
    flex-direction: column;
    span.upDown {
      width:20px;
      height:20px;
      border-radius: 50%;
      background-color: $color_green;
      color:white;
      text-align:center;
      padding-top: 2px;
      font-size: $fontsize_regular;
      margin-top:1px;
      cursor:pointer;
    }
    span.index {
      text-align: center;
      border:1px solid #333333;
      color:#333333;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-top:6px;
      font-size: $fontsize_small;
      padding-top:1px;
    }
  }
  div.image {
    width:100px;
    min-width: 100px;
    img {
      width:100%;
      height: auto;
    }
  }
  div.content-edit {
    width: 100%;
    div.coordinates {
      display:flex;
      flex-direction: row;
      div:first-child {
        padding-right: 4px
      }
      div:last-child {
        padding-left: 4px;
      }
    }
    div.coordinates-copier {
      display: flex; flex-direction: row; align-items: center;
      .coordinates-copier-label {
        padding:0 4px 0 4px;
      }

    }
    div.travel-modes {
      span.travel-mode {
        font-size: $fontsize_large;
        color:$color_disabled;
        padding-right: 4px;
        cursor: pointer;
      }
      span.travel-mode.selected {
        color:$color_green !important;
      }
    }
    div.image {
      width: 100%;
      img {
        width:100px;
        height:auto;
      }
    }
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0);
  }
}

div.c-surprise-step {
  animation: 0.5s ease-out 0s 1 slideInFromTop;
}