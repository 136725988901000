@import "../../../src/assets/css/Variables.New.scss";

// stolen from here: https://www.w3schools.com/howto/howto_css_switch.asp
.container {
  cursor: pointer;
  padding:2px;
  display: inline-block;

  .inner {
    margin-top: 2px;
    display: inline-block;
    position: relative;
    width: 50px;
    height: 30px;
    border-radius: 30px;
    background-color: $color_yes;
    transition: all .5s ease-in-out;
    border:1px solid $color_confirm;
    
    .slider {
      display: inline-block;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border:2px solid $color_confirm;
      background-color:white;
      position: absolute;
      top:0;
      right:0;
      transition: background-color .25s ease-in-out;
      transition: border-color .25s ease-in-out;
    }
  }
}

.container.off {
  .inner {
    background-color: #dddddd; 
    border-color: #dddddd;
  }
  .slider {
    left:0;
    margin-right:-26px;
    background-color: white;
    border-color: #dddddd;
  }
}

/** small **/
.container.small {
  .inner {
    width:30px;
    height:20px;
    .slider {
      width: 18px;
      height: 18px;
    }
  }
}

/** disabled **/
.container.disabled {
  .inner {
    border-color: silver;
  }
  .slider {
    background-color: silver;
    border-color: silver;
  }
}

