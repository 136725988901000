@import "../../../../../assets/css/Variables.New.scss";

div.c-surprise-selfie {
  box-sizing: border-box;
  display:inline-block;
  position:relative;
  width:33%;
  background-color: silver;

  img {
    width:100%;
    height:auto;
  }

  span.marketing-yes,
  span.marketing-no {
    position:absolute;
    bottom:10px;
    right:0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    color:white;
    font-size: $fontsize_smallest;
    padding:3px 4px 3px 4px;
  }

  span.marketing-yes {
    background-color: $color_green;
  }

  span.marketing-no {
    background-color: red;
  }

  span.show-settings,
  span.show-selfie {
    background-color: cornflowerblue;
    color:white;
    font-size:13px;
    position:absolute;
    bottom:10px;
    left:0;
    padding:1px 4px 0 4px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    cursor:pointer;
  }
  span.show-selfie {
    a {
      color:White;
    }
    bottom:30px;
  }

  div.settings {
    display:inline-block;
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    text-align: center;
    padding-bottom: 8px;
    div.spacer {
      height: 2px;
    }
  }

  div.deleted-by-user {
    background-color: $color_warning;
    color:white;
    display:inline-block;
    position:absolute;
    top:0; left:0;
    width:100%;
    padding:4px;
    span.icon {
      padding-right:2px;
    }
  }
}

div.c-surprise-selfie.edit {
  img {
    opacity: 0.5;
  }
}
