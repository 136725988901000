@import "./../../../assets/css/Variables.New.scss";

.container {
  background-color: red($color: #000000);
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  div.image {
    text-align: right;
    position: relative;
    width:100px;
    img {
      width:100%;
      height: auto;
    }
    span.count {
      position: absolute;
      padding:2px 6px 2px 6px;
      top:4px;
      right:4px;
      font-size: $fontsize_large;
      font-weight: bolder;
      border-radius: 4px;
      background-color: white;
      color: $color_green;
      border:1px solid $color_green;
    }
  }
  .list {
    width: 100%;
    table {
      display:block;
      font-size: $fontsize_regular;
      width: 100%;
      td:first-child {
        width: 100%;
      }
      td {
        div.sent {
          color: $color_green;
          font-size: $fontsize_regular;
        }
        div.order_comments {
          margin-top:4px;
          padding: 4px;
          background-color: orange;
          color:white;
        }
      }
      td.ticket,
      td.address {
        white-space: nowrap;
      }
      thead {
        th {
          text-align: left;
          font-weight:400;
          color:$color_green;
          padding-bottom: 8px;
        }
      }
    }  
  }
  
  
  
}