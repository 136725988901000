@import "../../assets/css/Variables.New.scss";

.container {
  position: relative;
  width: 100%;
  margin: 2px 0 2px 0;

  input {
    padding:2px;
    font-size: $fontsize_regular;
    width: 100%;
  }
  input:focus {
    outline: solid $color_green;
  }
  a {
    display: inline-block;
    color: $color_link;
  }
  a:hover {
    text-decoration: underline;
  }
}