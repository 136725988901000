span.icon {
  position: absolute;
  right: 2px;
  top: 3px;
  opacity: 0;
}
span.icon.copied {
  animation: keyframes_clipboardbutton 2s ease-in-out;
}

@keyframes keyframes_clipboardbutton {
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
}