@import "../../../../assets/css/Variables.New.scss";

.container {
  table {
    font-size: $fontsize_regular;
  }

  th {
    text-align: left;
    font-weight: normal;
    color:$color_green;
  }

  td:last-child {
    text-align: right;
  }

  tr.total td {
    color: #222222;
    padding-top:4px !important;
  }

  tr.total td.sum {
    border-top:1px solid #222222;
  }
}