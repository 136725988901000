@import "../../../src/assets/css/Variables.New.scss";

.container {
  padding: 2px;
  display: inline-block;
  border-radius: 4px;
  user-select:none;
  cursor: pointer;
  
  .inner {
    background-color: $color_yes;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: $fontsize_regular;
    line-height: $fontsize_regular;
    padding:0 28px 0 28px;
    border-radius: 4px;
    color:white;
    a, span {
      text-decoration: none;
      color:white;
    }
  }
}


/* Medium (default) ******/
.medium {
  .inner {
    height: 30px;
    font-size: $fontsize_large;
    line-height: $fontsize_large;
  }
}

/* Small ******/
.small {
  .inner {
    height: 20px;
    font-size: $fontsize_small;
    line-height: $fontsize_small;
  }
}


/* Disabled *********/
.disabled {
  cursor: not-allowed;
  .inner {
    background-color: $color_disabled;
  }
}
