@import "../../assets/css/Variables.New.scss";

.container {
  background-color:white;
  
  a {
    text-decoration: none;
    color: $color_green;
  }
  a:hover {
    text-decoration: underline;
  }

  span.link:after {
    content: " > "
  }
}


