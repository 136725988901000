@import "../../../src/assets/css/Variables.New.scss";

.container {
  padding-bottom: 8px;
  .inner {
    //padding: 0 0 4px 0;
    
    border-radius: 4px;
    border: 1px solid $color_secondary_50;
    .title {
      font-size: $fontsize_large;
      color: $color_secondary;
      //background-color: $color_secondary_10;
      border-top-left-radius: 4px; border-top-right-radius: 4px;
      padding: 4px;
      border-bottom: 1px solid $color_secondary_50;
    }
    .content {
      padding: 4px;
      font-size: $fontsize_regular;
    }
  }
}
.container:last-child {
  padding-bottom: 0;
}