@import "../../../../assets/css/Variables.New.scss";

.filter {
  .filtercolumn {
    padding: 4px;
    flex:1;
  }
  .filtergroup {
    padding-top:8px;
    .title {
      font-size: $fontsize_regular;
      color: $color_primary;
      padding-bottom: 4px;
    }
  }
  .filtergroup:first-child {
    padding-top:0;
  }
}

.actions {
  padding: 8px;
  font-size: $fontsize_regular;
}


.searchResult {
  width: 100%;
  overflow-x: scroll;
  .searchResult_counts {
    font-size: $fontsize_regular;
    margin: 8px 0 8px 0;
  }
  a {
    text-decoration: none;
  }
  table {
    background-color: white;
    width: 100%;
    padding: 8px 0 8px 0;
    td {
      font-size: $fontsize_large;
    }
    td.header {
      color: $color_secondary;
      font-size: $fontsize_regular;
    }
    tr:hover {
      a {
        text-decoration: underline $color_primary;
      }
      td {
        background-color: white;
      }
    }
  }
}