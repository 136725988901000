.locking {
  width: 100%;
  .info {
    padding-bottom: 2px;
  }
  .action_1 {
    textarea {width:100%};
  }
  .action_2 {
    text-align: right;
  }
}