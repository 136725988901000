@import "../../../src/assets/css/Variables.New.scss";

.container {
  text-align: center;
  padding: 8px 0 8px 0;

  .text {
    font-size: $fontsize_large;
    color: $color_primary;
    animation: keyframes_loader_text_keyframes 2s linear infinite;
  }

  /* line */
  .line {
    margin:4px 0 4px 0;
    height: 4px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
  }
  .line:before{
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: $color_primary;
    background: linear-gradient(90deg, $color_secondary 0%, $color_secondary 25%, $color_primary 66%, $color_primary 100%);
    animation: keyframes_loader_line_keyframes 2s linear infinite;
  }

  /* circle */
  .circle {
    border: 16px solid #f3f3f3;
    border-top: 16px solid $color_primary;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: keyframes_loader_circle_keyframes 1s linear infinite;
  }

  /* circle small */
  .circle.small {
    display: inline-block;
    border: 8px solid #f3f3f3;
    border-top: 8px solid $color_primary;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: keyframes_loader_circle_keyframes 1s linear infinite;
  }

  /* dots */
  .dots {
    .dot { 
      font-size: $fontsize_largest;
      color: $color_primary;
      animation: keyframes_loader_dots_keyframes 1s linear infinite; 
    }
    .dot:nth-child(2) { animation-delay: 250ms }
    .dot:nth-child(3) { animation-delay: 500ms }
  }
}

/* text animation */
@keyframes keyframes_loader_text_keyframes {
  0% { color: $color_primary; }
  50% { color: $color_secondary; }
  100% { color: $color_primary; }
}

/* line animation */
@keyframes keyframes_loader_line_keyframes {
  from {left: -200px; width: 30%;}
  50% {width: 30%;}
  70% {width: 70%;}
  80% { left: 50%;}
  95% {left: 120%;}
  to {left: 100%;}
}

/* circle animation */
@keyframes keyframes_loader_circle_keyframes {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* dots animation */
@keyframes keyframes_loader_dots_keyframes {50% { color: white }}
  