div#v-surprise-view {
  
  width: 100%;
}

div#v-surprise-view-inner {
  padding:16px 0 16px 0;
  display: flex;
  flex-direction: row;
}

div#v-surprise-view-inner-left,
div#v-surprise-view-inner-right { 
  width:50%;
}

div#v-surprise-view-inner-left {
  padding-right:4px;
}
div#v-surprise-view-inner-right { 
  padding-left: 4px;
}

@media screen and (max-width: 1140px) {
  div#v-surprise-view-inner {
    flex-direction: column-reverse;
  }
  div#v-surprise-view-inner-left,
  div#v-surprise-view-inner-right { 
    width:100%;
  }
}