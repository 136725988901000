@import "../../assets/css/Variables.New.scss";

.container {
  position: relative;
  .select {
    margin:2px;
    display: inline-block;

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    label {
      cursor:pointer;
      display:inline-block;
      color:white;
      background-color: $color_green;
      border-radius: 4px;
      border:0;
      font-size: $fontsize_regular;
      min-width: 100px;
      margin:1px 0 1px 0;
      user-select: none;
      outline: none;
      padding:2px 16px 3px 16px;
      white-space: nowrap;
    }
  }
  .select.disabled {
    label {
      background-color: silver;
      cursor: not-allowed;
    }
  }

  .selectedFile {
    margin:2px 2px 8px 2px;
    font-size: $fontsize_regular;
  }

  .selectButtons {
    display: flex; flex-direction: row;
  }
}


/* google picker in front */
.picker-dialog-bg {
  z-index: 200000 !important;
}

.picker-dialog {
  z-index: 200001 !important;
}