@import "./../../../../assets/css/Variables.New.scss";

div.c-outline-timelineitem {
  position: relative;
  white-space: nowrap;
  display:flex;
  justify-content: space-between;
  align-items:center;
  flex-direction: column;
  //padding: 4px 8px 4px 8px;
  min-width: 90px;
  div.start,
  div.end {
    padding:4px 0 4px 0;
  }
  div.icon {
    
    flex-direction: column;
    justify-content: center;
    background-color: rgba(1,1,1,0);
    //height:100%;
    position:absolute;
    top:50%;
    span {
      font-size: $fontsize_large;
      position: relative;
      //left:-24px;
      top:-11px;
      background-color: white;
    }
    div.icon-inner {
      background-color: yellowgreen;
    }
  }
  div.line {
    
    position: absolute;
    padding:20px 0 20px 0;
    top:0;
    //left:38px;
    left:50%;
    
    width:1px;
    height:100%;
    div.line-inner {
      height: 100%;
      background-color: black;
    }
  }
  div.line.trailing {
    padding-bottom: 4px;
  }
}