@import "./../../assets/css/Variables.New.scss";

.container {
  .checkbox_with_label {
    display:flex;
    flex-direction:row;
    align-content: center;
    input{
      outline: none;
      width: auto;
    }
    label {
      padding-left: 4px;
      font-size: $fontsize_small;
      color:black;
    }
  }
}