@import "../../assets/css/Variables.New.scss";

.react-date-picker__wrapper {
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  padding-bottom: 2px;
}
.react-date-picker__inputGroup {
  padding-left: 6px;
}
.react-calendar {
  //background-color: aqua;
}