@import "./../../../assets/css/Variables.New.scss";

.container {
  height:100%;
  .link {
    text-align: center;
    font-size: $fontsize_regular;
    padding:4px;
    color: $color_secondary;
  }
  .browser {
    height:100%;
    width: 100%;
    background-color: silver;
    border:2px solid white;
    iframe {
      border:0;
      width: 100%;
      height: 100%;
    }
  }
}