@import "./../../../assets/css/Variables.New.scss";

.container {
  // display: flex;
  // flex-direction: column;

  .billingaddress {
    padding: 2px 2px 2px 2px;
    white-space: pre-line;
    color: $color_text_secondary;
  }
}

