@import "../../../../assets/css/Variables.New.scss";

div.container {
  display:flex;
  flex-direction: row;
  margin:0 4px 0 4px;
  padding:4px;
  font-size: $fontsize_regular;

  div.icon {
    padding:0 2px;
    text-align: center;
    min-width: 22px;
  }
}

div.container {
  background-color: $color_warning;
  color:white;
}

div.container.priority,
div.container.critical {
  background-color: red;
  color:white;
  font-size: $fontsize_large;
}