@import "../../../src/assets/css/Variables.New.scss";

.container {
  .select {
    margin:2px;
    display: inline-block;

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    label {
      cursor:pointer;
      display:inline-block;
      color:white;
      background-color: $color_primary;
      border-radius: 4px;
      border:0;
      font-size: $fontsize_small;
      min-width: 100px;
      margin:1px 0 1px 0;
      user-select: none;
      outline: none;
      padding:2px 28px 3px 28px;
    }
  }

  .selectedFile {
    margin:2px 2px 8px 2px;
    font-size: $fontsize_regular;
  }
}


/* google picker in front */
.picker-dialog-bg {
  z-index: 200000 !important;
}

.picker-dialog {
  z-index: 200001 !important;
}