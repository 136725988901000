div#v-surprise-shipping {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}

div#v-surprise-shipping-image {
  width:100px;
  img {
    width: 100%;
    height: auto;
  }
}