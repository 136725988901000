.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.image {
  width:100px;
  img {
    width:100%;
    height: auto;
  }
}